import React from 'react';
import '../../App.css';
import ContactForm from '../ContactForm';


const SignUp = () => {
  return (
    <div>
      <h1></h1>
      <ContactForm />
    </div>
  );
};

export default SignUp;